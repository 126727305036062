import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, LinkField, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import NextLink from 'components/foundation/non-sitecore/NextLink';
import { ComponentProps } from 'lib/component-props';

type FeatureManualCardProps = ComponentProps & {
  fields: {
    title: Field<string>;
    categorySubheading: Field<string>;
    description: Field<string>;
    link: LinkField;
    noFollow?: Field<boolean>;
  };
  editorActive: boolean;
  hasCategorySubheader: boolean;
  headerComponent: JSX.Element | null;
};

const FeatureManualCard = (props: FeatureManualCardProps): JSX.Element => {
  const { editorActive, hasCategorySubheader } = props;

  return (
    <div className=" w-full flex p-4">
      <div className="mx-auto max-w-[400px] flex-col w-full flex shadow-card rounded bg-white border border-1 border-base-light overflow-hidden xl:max-w-none xl:grid xl:avid-grid-layout xl:m-0">
        {props.headerComponent}

        <div className="p-8 flex flex-1 justify-center flex-col xl:pl-0 xl:col-span-4">
          {(hasCategorySubheader || editorActive) && (
            <div className="uppercase accent-2 text-secondary tracking-[0.2rem] mb-4">
              <RichText field={props.fields?.categorySubheading} className="rte" />
            </div>
          )}

          <div className="intro-text-bold mb-4">
            <RichText field={props.fields?.title} className="rte font-avidwalsheimbold" />
          </div>
          <div className="mb-4">
            <RichText field={props.fields?.description} className="rte" />
          </div>
          <div>
            <NextLink
              field={props.fields?.link}
              linkCustomizations={props.fields}
              className="btn-link p-0 hover:bg-transparent focus:bg-transparent focus-visible:pr-4"
              hideText
            >
              <span>{props.fields?.link?.value?.text}</span>
              <FontAwesomeIcon icon={faChevronRight} className="text-xs pl-4" />
            </NextLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureManualCard;
